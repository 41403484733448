import React from "react";
import "./styles.scss";

const CareerTrajectory = () => {
  return (
    <div className="mlp-part-time-career-trajectory">
      <h2 className="career-trajectory-sub-title">CAREER TRAJECTORY</h2>
      <div className="career-trajectory-header">
        Here’s what your days..months..years can look like in 2024 and beyond!
        <br />
      </div>
     
      <div className="career">
        <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y1k9ym2epp3bm.png" className="dottedBg"  loading="lazy"/>
        <div className="column">
          <div className="career-card">
            <div className="title">
              <div className="indexNo">01</div><span className="title ml-mob-1">Digital Marketing Associate</span>
            </div>
            <div className="desc">
              Drive growth for the business using Paid Ads
            </div>
          </div>
          <div className="career-card">
            <div className="title alignTop">
              <div className="indexNo">01</div><span className="ml-mob-1">Ecommerce Ads Executive</span>
            </div>
            <div className="desc">
              Drive business growth  through Amazon, Flipkart etc
            </div>
          </div>
          <div className="career-card">
            <div className="title">
              <div className="indexNo">01</div>Growth Associate
            </div>
            <div className="desc">Drive growth for a brand beyond paid ads as well</div>
          </div>
        </div>
        <img className="mobDotted" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m20g4gef.png" loading="lazy" />
        <div className="flex">
          <div className="career-card">
            <div className="title">
              <div className="indexNo index-2">02</div>Marketing Manager
            </div>
            <div className="desc">
              Plan and execute strategies to drive results through your team
            </div>
          </div>
          <img className="mobDotted2" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m20g7l10.png" loading="lazy" />

          <div className="career-card mtopMob-1">
            <div className="title">
              <div className="indexNo">03</div>Brand Head
            </div>
            <div className="desc">
              Build and execute strategies to help all organic marketing
              channels work together
            </div>
          </div>
          <img className="mobDotted2" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m20g7l10.png" loading="lazy" />
          <img className="mobDotted2" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m20g7l10.png" loading="lazy" />
          <div className="career-card yellowBorder">
            <div className="title alignTop">
              <div className="indexNo">04</div>Marketing Head
            </div>
            <div className="desc">Drive business results profitably</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerTrajectory;